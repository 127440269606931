<script lang="ts">
	import { siteTitle, siteDescription } from "$lib/constants";
	import { page } from "$app/stores";
	import Search from "$lib/components/Search.svelte";
	import type { PageData } from "./$types";
	import Seo from "$lib/components/Seo.svelte";
	import { getSearchParams } from "$lib/searchParams";
	export let data: PageData;

	$: l1filter = decodeURI(getSearchParams($page.url, "l1", ""));
	$: l2filter = decodeURI(getSearchParams($page.url, "l2", ""));

	function shouldShow(url: string, l1: string, l2: string, depth: number) {
		if (l1 && !url.startsWith(l1)) {
			return false;
		}
		if (l2 && url.split("/")[1] != l2) {
			return false;
		}
		return true;
	}

	function shouldShowFilter(
		url: string,
		l1: string,
		l2: string,
		depth: number
	) {
		if (l1 && !url.startsWith(l1)) {
			return false;
		}
		return true;
	}

	function isSelected(url: string, selectedPath: string, depth: number) {
		if (url == "") {
			return url === selectedPath;
		} else {
			return selectedPath.startsWith(url);
		}
	}

	function makeFilterUrl(l1: string, l2: string, newl2: string) {
		let args: { [key: string]: string } = { l1, l2: newl2 };
		if (l2 == newl2) {
			delete args.l2;
		}
		if (!l1) {
			delete args.l1;
		}

		let r = Object.entries(args)
			.map(([key, value]) => {
				return `${key}=${value}`;
			})
			.join("&");
		if (r !== "") {
			return `?${r}`;
		} else return r;
	}
	const seo = {
		title: `${siteTitle}`,
		description: siteDescription,
	};
</script>

<Seo {...seo} />

<div id="search" class="search">
  <h1 class="search__title">
    <span>Search through </span>
    <p>over <bold>1,000,000</bold> study resources</p>
  </h1>

  <span id="searchinputcontainer" class="search-input"><Search value={data.question} /></span>
</div>

<div id="browse" class="container browse">
  <!--<RecordList value={data.relatedQuestions} /> -->

	{#if data.categories}
		<h1>Browse through study categories</h1>
		<ul id="l1" class="browse__level-one">
			{#each data.categories.filter((c) => c.depth == 1) as category}
				<li
					class={isSelected(category.url, l1filter, category.depth)
						? "l1 selected"
						: "l1 unselected"}
				>
					<a
						href={`/${makeFilterUrl(category.url, "", "")}`}
						title={category.description}
					>
						<h2>{category.title}</h2>
					</a>
				</li>
			{/each}
		</ul>
		<div id="categories" class="categories">
			<ul id="l2" class="categories__l2">
				{#each data.categories.filter((c) => c.depth == 2 && shouldShowFilter(c.url, l1filter, l2filter, c.depth)) as category}
					<li
						class={category.title == l2filter
							? "l2 selected"
							: "l2 unselected"}
					>
						<a
							on:click={
								(e) => setTimeout(() => window.scrollTo({
												behavior: "smooth",
												top: e.clientY,
											}), 0)
							}
							class="categories__anchor"
							href={`/${makeFilterUrl(
								l1filter,
								l2filter,
								category.title
							)}`}
							title={category.description}
						>
							{category.title}
						</a>
					</li>
				{/each}
			</ul>
			<ul id="l3" class="categories__l3">
				{#each data.categories.filter( (c) => shouldShow(c.url, l1filter, l2filter, c.depth) ) as category}
					<li>
						<a
							href={`/categories/${category.url}`}
							title={category.description}
						>
							<img
								src="/caticons/{category.categoryid}.png"
								alt={category.title}
							/>
							<div>
								<h4 class="hidde-words">{category.title}</h4>
								<p class="hidde-words">{category.qasetcount} QA sets</p>
							</div>
						</a>
					</li>
				{/each}
			</ul>
		</div>
	{/if}
</div>

<style>
	.search { 
		padding-inline: 1em;
		/* background: linear-gradient(
			to right,
			transparent,
			transparent 4%,
			var(--bg-light) 4%
		); */
	}
	.search__title > span {
		font-weight: 800;
		background: linear-gradient(
			-180deg,
			var(--brand-color-2),
			var(--brand-color) 40%
		);
		background-clip: text;
		color: transparent;
	}
	.search__title > p {
		font-size: var(-fs-600);
		font-weight: 700;
		color: var(--headers-color);
	}
	.search__title p bold {
		font-size: var(--fs-600);
		font-weight: 800;
		text-decoration: underline;
		text-decoration-color:  lightgray;
	}

	/*** Browse section*/
	.browse {
		padding: 0;
	}
	/** Browse title */
	.browse > h1 {
		padding-inline: 1.8rem;
		font-size: var(--fs-500);
		font-weight: 700;
		color: var(--brand-color);
	}
	/** ul element containing li of categories*/
	.browse__level-one {
		margin: 1em;
	}
	.browse__level-one li{
		border-radius: .4em;
		border: 1px  solid transparent;
		transition: border .4s;
	}
	.browse__level-one li:hover{
		border: 1px solid lightgray;
	}
	.browse__level-one h2 {
		color: var(--text-color);
		font-size: var(--fs-200);
	}

	/*** Categories Section */
	.categories {
		display: flex;
		padding-inline: .5em;
		gap: .5em;
	}
	.categories__anchor {
		display: block;
		padding: 0.2em;
		font-size: var(--fs-200);
		font-weight: 600;
		word-wrap: break-word;
		color: var(--headers-color);
		text-align: center;
	}
	.categories__l3 {
		display: flex;
		flex-wrap: wrap;
		gap: 1em;
		height: min-content;
		transition: all .5s;
		justify-content: center;
		width: 100%;
	}
	.categories__l3 li{
		height: fit-content;
		max-width: 22rem;
    width: 100%;
		box-shadow: 0px 2px 5px 1px lightgray;
		border-radius: .2em;
		transition: background-color .3s;
		opacity: 0;
		animation-name: fade;
		animation-duration: 800ms;
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
		animation-fill-mode: forwards;
	}
	@keyframes fade{
		to {
			opacity: 1;
		}
	}
	.categories__l3 li:hover{
		background-color: rgb(242, 248, 255);
	}
	.categories__l3 a{
		display: flex;
		flex-wrap: wrap;
		overflow: hidden;
		/* max-width: 21rem; */
		margin: 0.1rem;
	}
	.categories__l3 a div {
		display: flex;
		flex-direction: column;
		max-width: 7.5rem;
	}
	.categories__l3 img{
		max-width: 4rem;
		box-sizing: content-box;
		object-fit: cover;
		transition: scale .5s;
	}
	.categories__l3 h4 {
		font-size: var(--fs-200);
		margin: 0 0.5rem;
	}
	.categories__l3 li:hover img{
		scale: 0.9;
		scale: 1.1;
	}
	/** tab categories */
	.l1.selected {
		text-decoration: underline;
		color: var(--brand-color);
	}
	a {
		text-decoration: initial;
	}

	/** ul container */
	.categories__l2 {
		display: flex;
		flex-direction: column;
		font-size: var(--fs-200);
		gap: 0.5em;
		/* min-width: 7rem; */
		height: min-content;
		transition: background .4s;
	}
	.categories__l2 li {
		background-color: var(--bg-light);
	}
	.categories__l2 li:hover {
		background-color: hsl(0, 1%, 74%);
	}
	.categories__l2 li.selected {
		border: 1px solid var(--brand-color);
	}

	#l1 {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}


	#l3 a p {
		font-size: 0.75rem;
		color: var(--text-color);
		margin: 0 0.5rem;
	}

	h2 {
		font-size: 0.8rem;
		margin: 0 0.5rem;
		margin-right: 1rem;
		padding: 0.5em;
		text-align: center;
	}
	h4 {
		font-size: 1rem;
		margin: 0 0.5rem;
		margin-right: 1rem;
	}

	/* Media Queries */

	/* mobile-m 375px */
	@media (min-width: 23.43rem){
		.categories__l3 a div {
			display: flex;
			flex-direction: column;
			max-width: 10rem;
		}
	}
	/* mobile-l 425px */
	@media (min-width: 26.56rem){
		.categories__l3 li {
			max-width: 14rem;
		}
		.categories__l3 a div {
			max-width: 65%;
		}
	}
	/* tablet 768px */
	@media (min-width: 43.75rem) {
		.categories {
			display: flex;
			padding-inline: 1em;
			gap: 1em;
		}
		.search { 
			padding-inline: 1.8em;
		}
		.browse__level-one h2 {
			color: var(--text-color);
			font-size: var(--fs-300);
		}
		.categories__l3 h4 {
			font-size: var(--fs-300);
		}
		.categories__anchor {
			font-size: var(--fs-300);
		}
	}

	/* laptop 1024px */
	@media (min-width: 64rem) {
	}
	
	/* laptop-l 1440px */
	@media (min-width: 90rem) {}
</style>
